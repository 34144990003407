<template>

  <div class="wrapper">

    <Menu></Menu>

    <div class="body">

      <div class="row mr-0 ml-0">

        <div class="col-12 freebet-tournament-name">

          <span style="font-size: 1em !important;margin-left: 20px;margin-right: 20px;">
            <arrow-left-icon></arrow-left-icon>
          </span>
            Freebet For New Customers
        </div>
      </div>

      <div style="padding: 30px 10px 0px 10px; background: #404040 url(/assets/images/freebet-bg.png) no-repeat; background-size: cover; color: white">

        <div class="row mr-0 ml-0" >

            <div class="col-5" style="text-align: right;font-weight: bold;line-height: 17px;font-size: 1.2em;">

                {{ fixture.competitor_1 }}

            </div>

            <div class="col-2">

              <div class="text-center bold"> vs </div>

            </div>

            <div class="col-5" style="text-align: left;font-weight: bold;line-height: 17px;font-size: 1.2em;">

              {{ fixture.competitor_2 }}

            </div>

        </div>
        <div class="row">
          <div class="col-12">
            <div class="text-center bold" style="font-size: 0.7em;">
              {{ formatMatchDate(fixture.date) }}
            </div>
            <div class="text-center bold" style="font-size: 0.7em;">
              {{ getSportName(fixture.sport_id) }} / {{ fixture.country }} / {{ fixture.tournament }}
            </div>
          </div>
        </div>

        <div class="row" style="padding-right: 5px;padding-left: 5px;padding-bottom: 5px; justify-content: center;">

            <div
                class="col-auto"
                :class="getClasses(fixture.home)"
                style="margin: .1em .2em;"
                :sport_id="fixture.sport_id"
                specifier=""
                :match_id="fixture.match_id"
                :id="fixture.home.odd_id"
                @click="pickOutcome(fixture.home)"
                v-bind:disabled="parseInt(fixture.home.active) === 0 || parseInt(fixture.home.status) !== 0">

                    <div class="freebet-outcome-name">Home</div>
                    <div>
                      <span v-bind:class="getOddDirectionClass(fixture.home)"></span>
                      <span v-if="parseInt(fixture.home.active) === 0 || parseInt(fixture.home.status) !== 0"> - </span>
                      <span v-else> {{ fixture.home.odd }} </span>
                    </div>

            </div>

            <div
                class="col-auto"
                :class="getClasses(fixture.draw)"
                style="margin: .1em .2em;"
                :sport_id="fixture.sport_id"
                specifier=""
                :match_id="fixture.match_id"
                :id="fixture.draw.odd_id"
                @click="pickOutcome(fixture.draw)"
                v-bind:disabled="parseInt(fixture.draw.active) === 0 || parseInt(fixture.draw.status) !== 0">

                  <div class="freebet-outcome-name">Draw</div>
                  <div>
                    <span v-bind:class="getOddDirectionClass(fixture.draw)"></span>
                    <span v-if="parseInt(fixture.draw.active) === 0 || parseInt(fixture.draw.status) !== 0"> - </span>
                    <span v-else> {{ fixture.draw.odd }} </span>
                  </div>
            </div>

            <div
                class="col-auto"
                :class="getClasses(fixture.away)"
                style="margin: .1em .2em;"
                :sport_id="fixture.sport_id"
                specifier=""
                :match_id="fixture.match_id"
                :id="fixture.away.odd_id"
                @click="pickOutcome(fixture.away)"
                v-bind:disabled="parseInt(fixture.away.active) === 0 || parseInt(fixture.away.status) !== 0">

                  <div class="freebet-outcome-name">Away</div>
                  <div>
                    <span v-bind:class="getOddDirectionClass(fixture.away)"></span>
                    <span v-if="parseInt(fixture.away.active) === 0 || parseInt(fixture.away.status) !== 0"> - </span>
                    <span v-else> {{ fixture.away.odd }} </span>
                  </div>
            </div>

        </div>

      </div>

      <div class="row freebet-tournament-name mr-0 ml-0" style="padding-left: 10px;padding-right: 10px;">

        <div class="col-12 bold" style="text-align: center">
          Stake {{ stake | amount}} KES <span class="bold" style="font-size: 1.5em">.</span> Payout {{payout | amount}} KES
        </div>

      </div>

      <div v-show="action_reedem" class="row  mr-0 ml-0 ">
        <div style="padding: 10px;text-align: center;" class="col-12 center-text">
          <button class="btn btn-lg bg-blue redeem" v-bind:class="loading" @click="placeBet">
            Redeem Now
          </button>
        </div>

        <div class="col-12 main-freebet">
          <div class="main-paragraphs">
            <h2>How to redeem the tapabet FreeBet</h2>
            <p>We offer you a <a href="https://tapabet.co.ke/freebet">Freebet</a> worth KSHS 30/= to all new players. It’s easy to make your Freebet:</p>
            <ol>
              <li>Select Home team win (1), draw (X) or away team win (2), from day’s freebet game</li>
              <li>Enter your<strong> phone number</strong></li>
              <li>Choose your <strong>account password</strong> (6 or more characters)</li>
              <li>Select '<strong>Redeem Freebet</strong>' to place the bet.</li>
            </ol>
            <p>Your tapabet account will be created and your Freebet will be placed. Please don't forget to verify your account once you receive our SMS code; <strong>unverified accounts will lose their Freebet after 7 days</strong>. To view your bet, Go to ‘<a href="">My Bets</a>’.</p>
          </div>

          <div class="main-paragraphs">
            <h2>Terms and conditions for the tapabet freebet</h2>
            <p>tapabet Freebet offer is limited to one per customer and cannot be used in conjunction with any other offer.In the event of a customer opening more than one account in order to claim multiple FREEBETS, or claiming an offer on duplicate accounts, we reserve the right to suspend and close the accounts and make void any bets placed.</p>
            <p>When your Freebet wins, tapabet keeps its stake and pays the winnings into your tapabet account. You can use these winnings to bet on more matches.</p>
            <p>
              <em>
                <strong>Abuse</strong>: Persons attempting to take unfair advantage of the Freebet offer by creating multiple new accounts, inputting invalid phone numbers or depositing funds to reach the withdrawal threshold risk having all associated accounts closed and all funds confiscated.
              </em>
            </p>
            <p>General <a href="https://tapabet.co.ke/terms">Terms & Conditions</a> also apply</p>
          </div>

          <div class="main-paragraphs">
            <h2>Frequently Asked Questions</h2>
            <ul>
              <li>What is an tapabet freebet?</li>
              <li>How do I know I have a FreeBet?</li>
              <li>How can I place and redeem my FreeBet?</li>
              <li>How can I view my freebet?</li>
              <li>Can I withdraw freebet winnings</li>
              <li>Can I refer a friend to get the freebet?</li>
              <li>How to reset the password of your freebet account?</li>
              <li>Do the tapabet freebets work on mobile?</li>
            </ul>
            <h3>What is a tapabet freebet?</h3>
            <p>Our free Bet entitles you to place a bet at our expense! Select your bet, we pay the stake and you keep the winnings in your tapabet account. e.g. You place your Ksh 30/= FreeBet at odds of 2.50: if your selection wins, you get Ksh 45/= (stake of Ksh 30/= x odds of 2.50 = Ksh 75, minus Kshs 30/= ) without any risk on your side.</p>

            <h3>How do I know I have a FreeBet?</h3>
            <p>If you have one or more Free bets in your account, the FreeBet banner will be displayed on the website. A click on the banner opens the game's overview where you can find all of the relevant information about your available</p>

            <h3>How can I place and redeem a freebet on tapabet?</h3>
            <p>We offer a risk-free bet worth KSHS 30/= to all new players. It’s easy to use.</p>

            <h3>How can I view my freebet?</h3>
            <p>To view your bet, log in to tapabet.co.ke and choose ‘My Bets’.</p>

            <h3>How to Withdraw your freebet winnings?</h3>
            <p>You can withraw winnings from your freebet if the amount is more than 100/=. You can withdraw via SMS or Web.</p>
            <p><strong>SMS:</strong> Send an sms "W#Amount" to 40600 using the phone number linked to your account.</p>

            <p>
              <strong>WEB:<br></strong>
            <ol>
              <li>Open your web browser and type in: <a href="https://tapabet.co.ke/">tapabet.co.ke</a></li>
              <li>Select login</li>
              <li>Enter your number and password to access your account</li>
              <li>Select 'Menu' top left</li>
              <li>Select ‘Withdrawal’</li>
              <li>If requested, enter your first and last names in the fields provided</li>
              <li>Enter the amount you wish to withdraw (minimum 100/=)</li>
              <li>Select ‘Request Withdrawal’</li>
            </ol>
            You may send a withdrawal request at any time; we process withdrawals instantly. The minimum withdrawal amount is 100 Kshs. Please note that additional carrier fees apply when processing withdrawal requests to your account.
            </p>

            <h3>Are there restrictions on how to use my FreeBets?</h3>
            <p>The tapabet Freebet is only open to players aged 18yrs & above.</p>

            <h3>Can I refer a friend to get the freebet?</h3>
            <p>You can refer a friend to also play and win the freebet bonus. Share this link with your friend <a href="https://tapabet.co.ke/freebet">https://tapabet.co.ke/freebet</a></p>

            <h3>How do i reset password?</h3>
            <ol>
              <li>Open your web browser and type in: tapabet.co.ke</li>
              <li>When the tapabet page appears click on login</li>
              <li>Enter your phone number and click on ‘forgot password’</li>
              <li>You will receive an sms with a reset PIN</li>
              <li>Enter the PIN and choose your new password.</li>
            </ol>

            <h3>Do the tapabet freebets work on mobile?</h3>
            <p>tapabet freebet are as easily used on mobile, SMS, tapabet App as well as on desktop. Some customers claim that it may be easier to use the tapabet freebets on mobile. The tapabet website is user friendly and does not consume a lot of internet bundles. Using your mobile to place bets is the easiest.</p>

          </div>

        </div>

      </div>

      <div class="row mr-0 ml-0 " v-show="action_login" style="padding: 30px;background-color: white;">

        <div class="col-12">
          <div class="form-group">
            <span class="bold">Phone Number</span>
            <input type="email" class="form-control" name="mobile" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="e.g 0712 234567" v-model="email">
          </div>

          <div class="form-group">

            <span class="bold">Password</span>
            <input type="password" class="form-control" name="password" id="exampleInputPassword1" placeholder="Enter your password" v-model="password">
          </div>

          <div style="display: grid;grid-template-columns: 4fr;">

            <button class="btn bg-blue" style="font-weight: var(--font-weight-bold);" v-bind:class="loading" @click="login">REDEEM</button>
            <div style="text-align: center;line-height: 1.4em;" class="small mt-3">
              By clicking the button above, you agree to our <br> <router-link to="/terms" class="bold">terms and conditions</router-link>
            </div>

          </div>

        </div>

        <div class="col-12 main-freebet">
          <div class="main-paragraphs">
            <h2>How to redeem the tapabet FreeBet</h2>
            <p>We offer you a <a href="https://tapabet.co.ke/freebet">Freebet</a> worth KSHS 30/= to all new players. It’s easy to make your Freebet:</p>
            <ol>
              <li>Select Home team win (1), draw (X) or away team win (2), from day’s freebet game</li>
              <li>Enter your<strong> phone number</strong></li>
              <li>Choose your <strong>account password</strong> (6 or more characters)</li>
              <li>Select '<strong>Redeem Freebet</strong>' to place the bet.</li>
            </ol>
            <p>Your tapabet account will be created and your Freebet will be placed. Please don't forget to verify your account once you receive our SMS code; <strong>unverified accounts will lose their Freebet after 7 days</strong>. To view your bet, Go to ‘<a href="">My Bets</a>’.</p>
          </div>

          <div class="main-paragraphs">
            <h2>Terms and conditions for the tapabet freebet</h2>
            <p>tapabet Freebet offer is limited to one per customer and cannot be used in conjunction with any other offer.In the event of a customer opening more than one account in order to claim multiple FREEBETS, or claiming an offer on duplicate accounts, we reserve the right to suspend and close the accounts and make void any bets placed.</p>
            <p>When your Freebet wins, tapabet keeps its stake and pays the winnings into your tapabet account. You can use these winnings to bet on more matches.</p>
            <p>
              <em>
                <strong>Abuse</strong>: Persons attempting to take unfair advantage of the Freebet offer by creating multiple new accounts, inputting invalid phone numbers or depositing funds to reach the withdrawal threshold risk having all associated accounts closed and all funds confiscated.
              </em>
            </p>
            <p>General <a href="https://tapabet.co.ke/terms">Terms & Conditions</a> also apply</p>
          </div>

          <div class="main-paragraphs">
            <h2>Frequently Asked Questions</h2>
            <ul>
              <li>What is an tapabet freebet?</li>
              <li>How do I know I have a FreeBet?</li>
              <li>How can I place and redeem my FreeBet?</li>
              <li>How can I view my freebet?</li>
              <li>Can I withdraw freebet winnings</li>
              <li>Can I refer a friend to get the freebet?</li>
              <li>How to reset the password of your freebet account?</li>
              <li>Do the tapabet freebets work on mobile?</li>
            </ul>
            <h3>What is a tapabet freebet?</h3>
            <p>Our free Bet entitles you to place a bet at our expense! Select your bet, we pay the stake and you keep the winnings in your tapabet account. e.g. You place your Ksh 30/= FreeBet at odds of 2.50: if your selection wins, you get Ksh 45/= (stake of Ksh 30/= x odds of 2.50 = Ksh 75, minus Kshs 30/= ) without any risk on your side.</p>

            <h3>How do I know I have a FreeBet?</h3>
            <p>If you have one or more Free bets in your account, the FreeBet banner will be displayed on the website. A click on the banner opens the game's overview where you can find all of the relevant information about your available</p>

            <h3>How can I place and redeem a freebet on tapabet?</h3>
            <p>We offer a risk-free bet worth KSHS 30/= to all new players. It’s easy to use.</p>

            <h3>How can I view my freebet?</h3>
            <p>To view your bet, log in to tapabet.co.ke and choose ‘My Bets’.</p>

            <h3>How to Withdraw your freebet winnings?</h3>
            <p>You can withraw winnings from your freebet if the amount is more than 100/=. You can withdraw via SMS or Web.</p>
            <p><strong>SMS:</strong> Send an sms "W#Amount" to 40600 using the phone number linked to your account.</p>

            <p>
              <strong>WEB:<br></strong>
              <ol>
                <li>Open your web browser and type in: <a href="https://tapabet.co.ke/">tapabet.co.ke</a></li>
                <li>Select login</li>
                <li>Enter your number and password to access your account</li>
                <li>Select 'Menu' top left</li>
                <li>Select ‘Withdrawal’</li>
                <li>If requested, enter your first and last names in the fields provided</li>
                <li>Enter the amount you wish to withdraw (minimum 100/=)</li>
                <li>Select ‘Request Withdrawal’</li>
              </ol>
            You may send a withdrawal request at any time; we process withdrawals instantly. The minimum withdrawal amount is 100 Kshs. Please note that additional carrier fees apply when processing withdrawal requests to your account.
            </p>

            <h3>Are there restrictions on how to use my FreeBets?</h3>
            <p>The tapabet Freebet is only open to players aged 18yrs & above.</p>

            <h3>Can I refer a friend to get the freebet?</h3>
            <p>You can refer a friend to also play and win the freebet bonus. Share this link with your friend <a href="https://tapabet.co.ke/freebet">https://tapabet.co.ke/freebet</a></p>

            <h3>How do i reset password?</h3>
            <ol>
              <li>Open your web browser and type in: tapabet.co.ke</li>
              <li>When the tapabet page appears click on login</li>
              <li>Enter your phone number and click on ‘forgot password’</li>
              <li>You will receive an sms with a reset PIN</li>
              <li>Enter the PIN and choose your new password.</li>
            </ol>

            <h3>Do the tapabet freebets work on mobile?</h3>
            <p>tapabet freebet are as easily used on mobile, SMS, tapabet App as well as on desktop. Some customers claim that it may be easier to use the tapabet freebets on mobile. The tapabet website is user friendly and does not consume a lot of internet bundles. Using your mobile to place bets is the easiest.</p>

          </div>

        </div>

      </div>

      <div class="row mr-0 ml-0 " v-show="action_verify_password" style="padding: 30px;background-color: white;">

        <div class="col-12">

          A verification code has been sent to your phone number, please enter the code below to redeem your freebet

          <div class="form-group">

            <span class="bold">Verification Code</span>
            <input type="number" class="form-control" name="code" id="code" placeholder="Enter verification code" v-model="code">
          </div>

          <div style="display: grid;grid-template-columns: 4fr;">

            <button class="btn bg-blue" style="font-weight: var(--font-weight-bold);" v-bind:class="loading" @click="verifyAccount">REDEEM FREEBET</button>

          </div>

        </div>

      </div>

      <div v-show="action_success" class="row  mr-0 ml-0 " style="padding: 30px 10px;text-align: center;background-color: white;">

        <div class="col-4" style="text-align: right">
          <lazy-image height="50px" src="	/assets/icons/bet_placed.svg" />
        </div>

        <div class="col-8" style="text-align: left; padding: 0px 10px;">
          <div v-text="message"></div>
        </div>

      </div>

      <BottomFooter></BottomFooter>

      <bottom-navigation active-bets="0" page="freebet"></bottom-navigation>

    </div>

  </div>

</template>

<style scoped>
  body{
    font-size: 14px !important;
  }
  .main-freebet{
    line-height: 1.3em !important;
    padding: .75rem !important;
    background-color: #fff !important;
  }
  body *{
    box-sizing: border-box !important;
  }

  .main-paragraphs{
    padding: .75em 1em !important;
  }

  .main-paragraphs h1, .main-paragraphs h2, .main-paragraphs h3{
    line-height: 1em !important;
    padding-bottom: .5em !important;
  }

  h1, h2, h3, h4, h5, h6 {
    padding: 0 !important;
    margin: 0 !important;
    font-weight: 400 !important;
  }

  h2 {
    display: block !important;
    font-size: 1.5em !important;
    margin-block-start: 0.83em !important;
    margin-block-end: 0.83em !important;
    margin-inline-start: 0px !important;
    margin-inline-end: 0px !important;
    font-weight: bold !important;
    margin-bottom: .1em !important;
  }

  h3 {
    display: block !important;
    font-size: 1.17em !important;
    margin-block-start: 1em !important;
    margin-block-end: 1em !important;
    margin-inline-start: 0px !important;
    margin-inline-end: 0px !important;
    font-weight: bold !important;
  }

  .main-paragraphs p {
    margin-top: 0 !important;
    text-align: justify !important;
  }

  p {
    display: block !important;
    margin-block-start: 1em !important;
    margin-block-end: 1em !important;
    margin-inline-start: 0px !important;
    margin-inline-end: 0px !important;
  }

  ol, ul {
    display: block !important;
    list-style-type: decimal !important;
    margin-block-start: 1em !important;
    margin-block-end: 1em !important;
    margin-inline-start: 0px !important;
    margin-inline-end: 0px !important;
    padding-inline-start: 40px !important;
  }

  ul {
    list-style-type: disc !important;
  }

  li {
    display: list-item !important;
    text-align: -webkit-match-parent !important;
  }


</style>

<script>

import BottomFooter from './BottomFooter'
import Menu from './Menu'
import BottomNavigation from './BottomNavigation'
import axios from "@/services/api";

export default {
  name: 'Freebet',
  components: {
    Menu,
    BottomFooter,
    BottomNavigation,
  },
  data: function () {
    return {
      match_id: 0,
      game_id: 0,
      fixture: {},
      initial_fixture: {},
      market_group: {
        id: 0
      },
      totals: [],
      live: false,
      seconds: 0,
      possible_win: '',
      payout: '',
      tax: '',
      odd_id: '',
      outcome: {},
      msisdn: '',
      password:'',
      loading:'',
      code:'',
      action_verify_password: false,
      profile: {},
      action_success: false,
      message: ''
    }
  },
  mounted() {

    this.$store.dispatch("setCurrentPage","freebet");
    this.getMatch();
    this.reloadProfile();

  },
  computed: {

    successBagroundImg: function (){

      return this.getURL('/assets/icons/bet_placed.svg');
    },
    action_login: function (){

      var p = this.getProfile();
      return !p && !this.action_verify_password

    },
    action_reedem: function (){

      var p = this.getProfile();
      return p && !this.action_success;

    },
    stake : function (){

      return 30;

    },
    marketGroups: function () {

      return this.$store.state.market_groups
    },
    visibleMarkets: function () {

      return this.fixture.market;
      /*
      var max = 10;
      var x = 0;
      var markets = [];

      this.jQuery.each(this.fixture.market,function(k,v){

        x++;
        if(x < max ) {

          markets.push(v);
        }

      });

      return markets;

       */
    },
    collapsibleMarkets: function () {

      var max = 9;
      var x = 0;
      var markets = [];

      this.jQuery.each(this.fixture.market,function(k,v){

        x++;

        if(x > max ) {

          markets.push(v);
        }

      });

      return [];
    },
    home_team: function () {

      return this.getHomeCompetitorName(this.fixture.name)
    },
    away_team: function () {

      return this.getAwayCompetitorName(this.fixture.name)
    },
    event_time: function () {

      var minutes = parseInt(this.seconds / 60);
      //var seconds = this.seconds % 60;

      var min = minutes;

      if(minutes < 10 ) {

        min = "0"+min;
      }


      //console.log('secs '+sec);

      return min+"'";

      //return min+":"+sec;

    },
  },
  methods: {
    getClasses: function (o) {

      if(o === undefined || this.odd_id === undefined || o === false ) {

        return "btn-odd freebet-button small-txt mr-1 mt-1 match-" + this.fixture.match_id;
      }

      var isPicked = o.odd_id === this.odd_id ? 'freebet-button-picked': 'freebet-button';

      var st = parseInt(o.active) === 0 || parseInt(o.status) !== 0 ? 'locked btn-odd' : 'btn-odd';
      return st + " "+isPicked+" small-txt mr-1 mt-1 match-" + this.fixture.match_id;

    },

    placeBet: function () {

      this.reset();
      var p = this.getProfile();

      if(!p) {

        this.setError("Login","Please login to proceed");
        this.action_login = true;
        //this.$router.push({ name: 'login', params: { } });
        return;
      }

      var bet_amount = this.stake;

      var bets = [];

      bets.push({
        odd_id: this.odd_id,
        match_id: this.fixture.match_id
      });

      var utm_source = this.getValue("utm_source");
      var utm_medium = this.getValue("utm_medium");
      var utm_campaign = this.getValue("utm_campaign");
      var referrer = this.getValue("referrer");

      var data = {
        stake: bet_amount,
        bets: bets,
        bet_type: 1,
        source: this.isMobile() ? 2 : 1,
        utm_source: utm_source,
        utm_medium: utm_medium,
        utm_campaign: utm_campaign,
        referrer: referrer
      };

      this.loading = 'loading';

      var vm = this;

      var path = process.env.VUE_APP_URL_BET.replace("{profile_id}", p.d);

      axios.post(path, JSON.stringify(data),{
        headers: {
          'api-key': vm.getAuth()
        },
      })
          .then(res => {

            vm.loading = '';
            var profile = res.data.message.profile;
            var message = res.data.message.message;
            vm.message = message;
            vm.setProfile(profile);

            vm.action_success = true;
            vm.action_reedem = false;
            vm.action_login = false;
            vm.action_verify_password = false;

            vm.setSuccess("Success", message);

          })
          .catch(err => {

            vm.loading = '';
            if (err.response) {

              if(parseInt(err.response.status) === 401 || parseInt(err.response.status) === 400 || parseInt(err.response.status) === 428 ) {

                vm.setError("Session Expired", "Your session on this device has expired");
                vm.logout();
                return;

              } else {
                vm.setError("Failed", err.response.data.message)
                console.log(JSON.stringify(err.response.data.message));
              }

            } else if (err.request) {

              vm.setError("Network Error", "Check your network connection and try again")
              console.log(JSON.stringify(err.request));

            } else {

              vm.setError("Network Error", "Check your network connection and try again")
              console.log(JSON.stringify(err));

            }
          })
    },

    login: function () {

      this.reset();
      this.removeAuth();

      if (this.msisdn.length < 9) {

        this.setError("Invalid Mobile Number", "Please enter a valid mobile number");
        return;

      }

      if (this.password.length < 4) {

        this.setError("Invalid Password", "Please enter a password of more than 4 characters");
        return;

      }

      this.loading = 'loading';

      var vm = this;

      var path = process.env.VUE_APP_URL_LOGIN;

      var utm_source = this.getValue("utm_source");
      var utm_medium = this.getValue("utm_medium");
      var utm_campaign = this.getValue("utm_campaign");
      var referrer = this.getValue("referrer");


      axios.post(path, JSON.stringify({
        m: vm.getBackendValue(this.msisdn),
        password: this.password,
          utm_source: utm_source,
          utm_medium: utm_medium,
          utm_campaign: utm_campaign,
          referrer: referrer
      }))
          .then(res => {

            vm.loading = '';
            var status = res.data.status;
            var profile = res.data.message;

            if(parseInt(status) === 201 ) {

                vm.action_verify_password = true;
                vm.profile = profile;
                vm.setSuccess("Success", "Account created successful");
                return;
            }

            vm.setProfile(profile);
            var auth = profile.a;
            vm.setAuth(auth);

            vm.setSuccess("Success", "Login successful");
            vm.placeBet();

          })
          .catch(err => {

            vm.loading = '';
            if (err.response) {

              vm.setError("Failed",err.response.data.message)
              if(parseInt(err.response.status) === 428 ) {

                vm.action_verify_password = true;
                return;
              }

            }
            else if (err.request) {

              vm.setError("Network Error", "Check your network connection and try again")

            }
            else {

              console.log(JSON.stringify(err,undefined,4));

            }

          })

    },

    verify: function () {

      this.reset();
      this.removeAuth();


      if (this.code.length < 4) {

        this.setError("Invalid Password", "Please enter a password of more than 4 characters");
        return;

      }

      this.loading = 'loading';

      var vm = this;

      var path = process.env.VUE_APP_URL_LOGIN;

      var currentDate = new Date();
      var login_tag = parseInt(currentDate.getTime()+''+Math.floor(Math.random() * (9 * (Math.pow(10, 2)))) + (Math.pow(10, 2)));
      this.setValue("login_tag",login_tag);

      var utm_source = this.getValue("utm_source");
      var utm_medium = this.getValue("utm_medium");
      var utm_campaign = this.getValue("utm_campaign");
      var referrer = this.getValue("referrer");

      axios.post(path, JSON.stringify({
        m: vm.getBackendValue(this.msisdn),
        password: this.password,
        login_tag: login_tag,
        utm_source: utm_source,
        utm_medium: utm_medium,
        utm_campaign: utm_campaign,
        referrer: referrer
      }))
          .then(res => {

            vm.loading = '';
            var status = res.data.status;

            if(parseInt(status) === 201 ) {

              this.action_verify_password = true;
              vm.setSuccess("Success", "Account created successful");
              return;
            }

            var profile = res.data.message;
            vm.setProfile(profile);
            var auth = profile.a;
            vm.setAuth(auth);

            vm.setSuccess("Success", "Login successful");
            vm.placeBet();

          })
          .catch(err => {

            vm.loading = '';
            if (err.response) {

              vm.setError("Failed",err.response.data.message)
              console.log(JSON.stringify(err.response.data.message));

            }
            else if (err.request) {

              vm.setError("Network Error", "Check your network connection and try again")
              console.log(JSON.stringify(err.request));

            }
            else {

              vm.setError("Network Error", "Check your network connection and try again")
              console.log(JSON.stringify(err));

            }

          })

    },

    verifyAccount: function () {

      this.reset();
      this.removeAuth();

      if (this.code.length < 4) {

        this.setError("Invalid Code", "Please enter a valid verification code");
        return;

      }

      this.loading = 'loading';

      var vm = this;

      var path = process.env.VUE_APP_URL_VERIFY;

      var currentDate = new Date();
      var login_tag = parseInt(currentDate.getTime()+''+Math.floor(Math.random() * (9 * (Math.pow(10, 2)))) + (Math.pow(10, 2)));
      this.setValue("login_tag",login_tag);

      axios.post(path, JSON.stringify({
        m: vm.getBackendValue(this.msisdn),
        code: this.code,
        login_tag: login_tag
      }))
          .then(res => {

            vm.loading = '';
            var profile = res.data.message;
            vm.setProfile(profile);
            var auth = profile.a;
            vm.setAuth(auth);

            console.log('verifyAccount got auth '+auth);

            vm.placeBet();
            vm.setSuccess("Your account has been verified successfully");

          })
          .catch(err => {

            vm.loading = '';
            if (err.response) {

              vm.setError("Failed",err.response.data.message)
              console.log(JSON.stringify(err.response.data.message));

            }
            else if (err.request) {

              vm.setError("Network Error", "Check your network connection and try again")
              console.log(JSON.stringify(err.request));

            }
            else {

              vm.setError("Network Error", "Check your network connection and try again")
              console.log(JSON.stringify(err));

            }

          })

    },

    pickOutcome: function (outcome){

      this.outcome = outcome;
      this.odd_id = outcome.odd_id;
      var odd = outcome.odd;
      this.possible_win = this.stake * odd;
      this.tax = (this.possible_win - this.stake) * 0; //0.2
      this.payout = this.possible_win - this.stake
    },
    getHomeCompetitorName: function (matchName) {

      if (matchName === undefined) {

        return "";
      }

      return matchName.split('vs.')[0];
    },

    getAwayCompetitorName: function (matchName) {

      if (matchName === undefined) {

        return "";
      }
      return matchName.split('vs.')[1];
    },

    getMatch: function () {

      var vm = this;
      var path = process.env.VUE_APP_URL_FREEBET;

      axios.post(path, JSON.stringify({game_id: this.game_id}))
          .then(res => {

            if(Array.isArray(res.data.message)) {

              vm.fixture = res.data.message[0];

            } else {

              vm.fixture = res.data.message

            }

            vm.pickOutcome(vm.fixture.draw);

          })
          .catch(error => {

            console.log(error)

          })
    },
    getOddDirectionClass: function (outcome) {

      if(parseInt(outcome.active) === 0 || parseInt(outcome.status) !== 0) {

        return '';
      }

      if(parseFloat(outcome.odd) >= parseFloat(outcome.previous_odds) ) {

        return 'arrow green up';

      }

      return 'arrow red down';

    }

  },
  filters: {

    amount: function (val){

      if(val === undefined ) {

        return val

      }

      return parseFloat(val).toFixed(2).toLocaleString()

    }

  }
}
</script>